<template>
  <div class="house-list">
    <div class="search-box">
      <van-icon name="arrow-left" @click="goHome" />
      <van-search v-model="search" show-action placeholder="请输入楼盘名或地段名" @search="onSearch">
        <template #action>
          <img src="../../assets/images/dingwei01.png" alt="" @click="goMap" />
        </template>
      </van-search>
    </div>
    <van-dropdown-menu class="menu-box">
      <van-dropdown-item :title="title" v-model="areaValue" :options="areaOption" @change="changeArea" />
      <van-dropdown-item title="总价" ref="item">
        <van-cell title="不限" @click="rePrice" />
        <div class="price-box">
          <div class="price-input">
            <input type="number" placeholder="最低价格" v-model="lowPrice" />
            <div>-</div>
            <input type="number" placeholder="最高价格" v-model="highPrice" />
          </div>
          <van-button type="danger" @click="determine"> 确认 </van-button>
        </div>
      </van-dropdown-item>
      <van-dropdown-item :title="tagTitle" v-model="moreValue" :options="moreOption" @change="changeTag" ref="secondHouse" @open="showBtn = true"  @close="showBtn = false">

        <div class="searchlist">
          <div class="area">
            <div class="title">建筑面积(m²)</div>
            <div class="area-box">
              <div class="area-item" v-for="item in areaList" :key="item" @click="areaCheck = item" :class="{ active: areaCheck == item }">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="renovation">
            <div class="title">装修</div>
            <div class="renovation-box">
              <div class="renovation-item" v-for="item in renovationList" :key="item" @click="renovationCheck = item" :class="{ active: renovationCheck == item }">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="orientation">
            <div class="title">朝向</div>
            <div class="orientation-box">
              <div class="orientation-item" v-for="item in orientationList" :key="item" @click="orientationCheck = item" :class="{ active: orientationCheck == item }">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="houseType">
            <div class="title">房型</div>
            <div class="houseType-box">
              <van-radio-group v-model="houseRadio">
                <van-radio name="不限" shape="square" icon-size="14px" @click="houseRadioClick">不限</van-radio>
              </van-radio-group>
              <van-checkbox-group v-model="houseCheckbox" direction="horizontal" icon-size="14px">

                <van-checkbox shape="square" v-for="item in checkboxList" :key="item" :name="item" @click="houseCheckboxClick">{{ item }}</van-checkbox>
              </van-checkbox-group>
            </div>
          </div>
          <div class="type">
            <div class="title">类型</div>
            <div class="type-box">
              <van-radio-group v-model="typeRadio">
                <van-radio name="不限" shape="square" icon-size="14px" @click="typeRadioClick">不限</van-radio>
              </van-radio-group>
              <van-checkbox-group v-model="typeCheckbox" direction="horizontal" icon-size="14px">

                <van-checkbox shape="square" v-for="item in typeList" :key="item" :name="item" @click="typeCheckboxClick">{{ item }}</van-checkbox>
              </van-checkbox-group>
            </div>
          </div>

        </div>
      </van-dropdown-item>

    </van-dropdown-menu>
    <div class="fixed-btn" v-if="showBtn">
      <van-button type="primary" @click="clear">清空条件</van-button>
      <van-button type="info" @click="determine1">确定</van-button>
    </div>
    <div class="building-box">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">
        <div class="box-item" v-for="item in secondHandHouse" :key="item.id">
          <house-item :item="item" :types="1" />
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
import buildingItem from "../../components/BuildingItem.vue";
import { post } from "../../network/Interface";
import HouseItem from "@/components/HouseItem";
export default {
  name: "secondHouseList",
  data() {
    return {
      houseCheckbox: [],
      typeCheckbox: [],
      typeRadio: "不限",
      houseRadio: "不限",
      search: "",
      areaValue: "",
      House: "",
      title: "区域",
      secondHandHouse: [],
      areaOption: [
        // { text: "不限", value: 0 },
        // { text: "思明", value: 1 },
        // { text: "湖里", value: 2 },
        // { text: "集美", value: 3 },
        // { text: "海沧", value: 4 },
        // { text: "同安", value: 5 },
        // { text: "翔安", value: 6 },
      ],
      cityArea: "",
      moreValue: "",
      tagTitle: "更多",
      moreOption: [
        // { text: "不限", value: 0 },
        // { text: "新房", value: 1 },
        // { text: "海景房", value: 2 },
        // { text: "别墅", value: 3 },
      ],
      areaList: [
        "50以下",
        "50-70",
        "70-90",
        "90-110",
        "110-150",
        "150-200",
        "200-300",
        "300以上",
      ],
      renovationList: ["毛坯房", "简单装修", "中等装修", "精装修", "豪华装修"],
      orientationList: [
        "正东",
        "正西",
        "正南",
        "正北",
        "东南",
        "东北",
        "西南",
        "西北",
        "东西",
        "南北",
      ],
      typeList: ["普通住宅", "公寓", "老公房", "别墅", "商铺", "其他"],
      checkboxList: ["1室", "2室", "3室", "4室", "5室", "5室以上"],
      lowPrice: "",
      highPrice: "",
      typeValue: [],
      buildingList: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      total: 0,
      id: 0,
      areaCheck: "",
      renovationCheck: "",
      orientationCheck: "",
      showBtn:false
    };
  },
  mounted() {},
  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
    if (this.id == undefined || this.id == "" || this.id == null) {
      this.id = 0;
    }
    this.message = this.$route.query.message;
    console.log("zz", this.id + "--" + this.message);
    this.search = this.message;
    this.commid = this.$route.query.commid;
    console.log("获取到我的小区id是多少" + this.commid);
  },
  methods: {
    goMap() {
      this.$router.push("/map");
    },
    onLoad() {
      this.loading = true;
      this.pageNum++;
      this.getHouseList();
    },

    onSearch() {
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },

    goHome() {
      this.$router.push("/");
    },
    toggle(index) {
      this.typeRadio = "";
      this.$refs.checkboxes[index].toggle();
    },
    getHouseList() {
      console.log("saassaa", this.search);
      console.log("this", this.orientationCheck);
      let area = "";
      if (this.areaCheck == "50以下") {
        area = this.areaCheck.replace("以", "").replace("下", "");
      } else if (this.areaCheck == "300以上") {
        area = this.areaCheck.replace("以", "").replace("上", "");
      } else {
        area = this.areaCheck.replace("-", ",");
      }
      console.log(this.areaCheck);
      console.log(
        "zzzzzzzzzzzzzzzzzzzzz---------------------------------------------" +
          this.commid
      );
      if(this.highPrice==0){
        this.lowPrice="";
      }
      const data = {
        search: this.search,
        areaName: this.areaValue,
        lowPrice: this.lowPrice,
        highPrice: this.highPrice,
        houseType: this.typeValue.join(","),
        moreValue: this.moreValue,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        areaCheck: area,
        finish: this.renovationCheck,
        orientation: this.orientationCheck,
        commid: this.commid,
      };
      this.$post("secondHouse/getSecondHouseList", data).then((res) => {
        if (res.status == 200) {
          this.finished = false;
          this.loading = false;
          let flag = false;
          this.secondHandHouse = this.secondHandHouse.concat(
            res.data.secondHouses
          );
          for (var i = 0; i < this.secondHandHouse.length; i++) {
            console.log(this.secondHandHouse[i].title);
          }
          //防止出现每页楼盘长度等于查询的楼盘长度
          if (
            res.data.secondHouses.length == res.pageSize &&
            res.data.count % this.pageSize == this.pageNum &&
            res.data.count / this.pageSize == 0
          ) {
            flag = true;
          }
          if (
            this.secondHandHouse.length >= res.data.count ||
            res.data.secondHouses.length < this.pageSize ||
            flag
          ) {
            this.finished = true;
          }
          //标签
          // this.moreOption = [];
          // this.moreOption.push({ text: "不限", value: "" });
          // res.data.tags.forEach((item) => {
          //   this.moreOption.push({ text: item, value: item });
          // });

          //区域
          this.cityArea = res.data.cityArea.areaName;
          this.areaOption = [];
          this.areaOption.push({ text: "不限", value: "" });
          res.data.cityAreaList.forEach((e) => {
            this.areaOption.push({
              text: this.cityArea + "\xa0" + e.areaName,
              value: e.areaName,
            });
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    changeArea(i) {
      console.log(i);
      // this.title = i
      if (i == "") {
        this.title = "区域";
      } else {
        this.title = i;
      }
      this.areaValue = i;
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },
    changeTag(i) {
      console.log(i);
      // this.title = i
      if (i == "") {
        this.tagTitle = "更多";
      } else {
        this.tagTitle = i;
      }
      this.moreValue = i;
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },
    openDropdown(){
      console.log('111111')
    },
    houseRadioClick() {
      this.houseRadio = "不限";
      this.houseCheckbox = [];
    },
    houseCheckboxClick() {
      this.houseRadio = "";
    },
    typeRadioClick() {
      this.typeRadio = "不限";
      this.typeCheckbox = [];
    },
    typeCheckboxClick() {
      this.typeRadio = "";
    },
    clear() {
      this.houseRadio = "不限";
      this.houseCheckbox = [];
      this.typeRadio = "不限";
      this.typeCheckbox = [];
      this.renovationCheck = "";
      this.areaCheck = "";
      this.orientationCheck = "";
      this.getHouseList();
    },
    determine() {
      // this.$refs.item.toggle(false);
      // this.$refs.tagTitle.toggle(false);
      // console.log(this.typeValue) 数组[1,2,3]
      // console.log(this.typeRadio) 不限或""
      //  this.typeValue.forEach(item =>{
      //               console.log(item)
      //           })
      //               console.log("----------------")
      // this.typeRadio.forEach(item =>{
      //     console.log(item)
      // })
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
      this.$refs.item.toggle(false);
      this.$refs.typeItem.toggle(false);
    },
    determine1() {
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
      this.$refs.secondHouse.toggle(false);
    },
    rePrice() {
      this.lowPrice = "";
      this.highPrice = "";
      this.buildingList = [];
      this.secondHandHouse = [];
      this.pageNum = 1;
      this.getHouseList();
    },
  },

  components: {
    HouseItem,
    buildingItem,
  },
};
</script>
<style lang="less" scoped>
.house-list {
  /deep/.search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    right: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 10px;
    height: 55px;
    border-bottom: 1px solid #ddd;
    .van-icon {
      color: #9f9f9f;
      font-size: 18px;
    }
    .van-search {
      flex: 1;
      padding: 0 0 0 10px;
      .van-search__content {
        flex: 1;
        background-color: #f1f1f1;
        color: #444;
        border-radius: 5px;
      }
      input::-webkit-input-placeholder {
        color: #666;
      }
      .van-search__action {
        padding: 0 5px 0 15px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .menu-box {
    position: fixed;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 99;
    height: 50px;
    .price-box {
      display: flex;
      padding: 10px 25px;
      background-color: #fafafa;
      align-items: center;
      justify-content: space-between;
      .price-input {
        display: flex;
        align-items: center;
        div {
          margin: 0 10px;
        }
        input {
          width: 85px;
          height: 35px;
          background-color: #eee;
          outline: unset;
          border: 0;
          padding: 7px;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 14px;
          color: #000;
          &::-webkit-input-placeholder {
            color: #999;
          }
        }
      }
      .van-button {
        width: 80px;
        height: 30px;
        background-color: #00d3c4;
        border-radius: 5px;
        border-color: #00d3c4;
      }
    }
    .type {
      .type-btn {
        background-color: #fafafa;
        padding: 20px;
        display: flex;
        justify-content: center;
        /deep/.van-button {
          background: #00d3c4;
          width: 140px;
          height: 40px;
          border-radius: 5px;
          color: #fff;
          font-size: 18px;
          border: unset;
          letter-spacing: 1px;
        }
      }
    }
  }
  .building-box {
    margin-top: 105px;
    .box-item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      background-color: #fff;
    }
  }
  .searchlist {
    height: 450px;
    height:100vh;
    // overflow-y: auto;
    position: relative;
    top: -50px;
    font-size: 15px;
    color: #535353;
    margin-top: 55px;
    z-index: 0;
    .title {
      margin: 14px;
    }
    .area-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .area-item {
        width: 25%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background: #fbe6e3;
          color: #e03e3f;
          border-radius: 5px;
        }
      }
    }
    .renovation-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .renovation-item {
        width: 25%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background: #fbe6e3;
          color: #e03e3f;
          border-radius: 5px;
        }
      }
    }
    .orientation-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .orientation-item {
        width: 25%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active {
          background: #fbe6e3;
          color: #e03e3f;
          border-radius: 5px;
        }
      }
    }

    .houseType-box {
      position: relative;

      .van-radio-group {
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 10;
      }
      /deep/.van-checkbox--horizontal {
        margin-right: 0;
      }
      .van-checkbox-group {
        position: relative;
        &::before {
          content: "";
          width: 25%;
          height: 40px;
        }
      }
      .van-checkbox {
        width: 25%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .type-box {
      position: relative;
      height: 100px;
      .van-radio-group {
        height: 40px;
        width: 25%;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 10;
      }
      /deep/.van-checkbox--horizontal {
        margin-right: 0;
      }
      .van-checkbox-group {
        position: relative;
        &::before {
          content: "";
          width: 25%;
          height: 40px;
        }
      }
      .van-checkbox {
        width: 25%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  /deep/.van-dropdown-item__content {
    max-height: 90%;
  }
  .fixed-btn {
    padding: 0 15px;
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 9999;
    .van-button--primary {
      background: #c9c9c9;
      border: 0;
      width: 141px;
      height: 39px;
      border-radius: 5px;
      font-size: 17px;
    }
    .van-button--info {
      background-color: #00d3c4;
      border: 0;
      width: 141px;
      height: 39px;
      border-radius: 5px;
      font-size: 17px;
    }
  }
}
</style>